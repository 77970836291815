<template>
    <el-select
        v-model="type"
        class="filter"
        @change="search"
    >
        <el-option
            v-for="item in typeList"
            :key="item.param"
            :value="item.param"
            :label="labelItem(item)"
        />
    </el-select>
</template>


<script>
    import _assign from 'lodash/assign'
    import typeList from '~/components/searchPage/typeActivitiesLog'

    export default {

        data() {
            return {
                type: this.$route.query.type || '',
                typeList,
            }
        },

        methods: {
            search() {
                this.$router.push({
                    query: _assign({}, this.$route.query, {
                        type: this.type,
                        page: 1,
                    }),
                })
            },

            labelItem(item) {
                return this.$t(item.text)
            },
        },
    }
</script>
