// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="tag-profile">
        <div class="section-title-line hidden-md-down">
            <h4 class="text-uppercase overflow-hidden">
                {{ tag.name }}
            </h4>
            <hr class="filler-line">
        </div>

        <stats :tag="tag" :stats="statsTitle" />

        <div class="mt-3 hidden-md-down">
            <popular-tags :tags="popularTags" />
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import Stats from './Stats.vue'
    import PopularTags from '../sidebar/PopularTags.vue'

    export default {

        components: {
            Stats,
            PopularTags,
        },
        props: {
            tag: {
                type: Object,
                required: true,
            },
        },

        computed: {
            ...mapState('promoted', {
                popularTags: 'tags',
            }),

            statsTitle() {
                return {
                    posts: this.$t('detailTag.posts'),
                    questions: this.$t('detailTag.questions'),
                    followers: this.$t('detailTag.followers'),
                }
            },
        },

        mounted() {
            this.fetchPopularTags()
        },

        methods: {
            ...mapActions('promoted', ['fetchPopularTags']),
        },
    }
</script>
