// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="container pt-5 pb-1">
        <el-alert
            :closable="false"
            type="info"
            title=""
            show-icon
            class="p-2"
        >
            <b> You are alredy unsubscribed!</b>
        </el-alert>
        <el-button
            class="mt-1"
            size="medium"
            type="primary"
            plain
            @click="goHome"
        >
            <i class="el-icon-back" /> Go home
        </el-button>
    </div>
</template>
<script>
    export default {
        name: 'AlreadyUnsubscribed',
        methods: {
            goHome() {
                this.$router.push('/')
            },
        },
    }
</script>
