<template>
    <div class="container px-0 py-3">
        <div class="d-flex flex-wrap justify-content-center pb-1">
            <DatePicker
                :show-button="false"
                class="filterDate"
            />

            <ActivityFilter class="flex-grow-1 m-05" />

            <FilterType class="filterType m-05" />

            <Search
                query-param="q"
                class="Search flex-grow-1"
                :placeholder="$t('activities.search')"
            />
        </div>

        <Table :activities="activities" :total="pagination.total" />

        <Pagination
            v-if="pagination"
            :current-page="pagination.current_page"
            :last-page="pagination.last_page"
        />
    </div>
</template>

<script>
    import _assign from 'lodash/assign'
    import { get as fetchActivities } from '~/api/activities-log'
    import { handlePageError } from '~/utils/pages'

    import pageTitle from '~/components/mixins/pageTitle'
    import DatePicker from '~/components/widgets/DatePicker.vue'
    import Search from '~/components/searchPage/SearchInMyContentPage.vue'
    import ActivityFilter from '~/components/searchPage/FilterActionActivityLog.vue'
    import FilterType from '~/components/searchPage/FilterByTypeActivityLog.vue'
    import Table from '~/components/activityLog/List.vue'
    import Pagination from '~/components/Pagination.vue'
    import { loginWithSSR } from '~/utils/login'

    export default {
        middleware: 'auth',
        watchQuery: true,
        scrollToTop: true,

        components: {
            DatePicker,
            Search,
            ActivityFilter,
            FilterType,
            Table,
            Pagination,
        },

        mixins: [
            pageTitle({ defaultTitle: 'activities.title' }),
        ],

        async asyncData({
            store, query, error, req, redirect,
        }) {
            try {
                const data = await fetchActivities({
                    limit: store.state.settings.perPage,
                    page: query.page || 1,
                    ...query,
                })

                return {
                    activities: data.data,
                    pagination: data.meta,
                    errors: null,
                }
            } catch (e) {
                if (e.response && e.response.status === 401) {
                    store.commit('auth/SET_USER', null)
                    store.commit('auth/SET_GUEST', true)

                    return loginWithSSR(req, redirect)
                }

                if (e.response && e.response.status === 422) {
                    return { activities: [], pagination: [], errors: e.response.data.errors }
                }

                return handlePageError(error, e)
            }
        },

        methods: {
            filter(field, value) {
                this.$router.push({
                    query: _assign({}, this.$route.query, {
                        [field]: value,
                        page: 1,
                    }),
                })
            },
        },
    }
</script>

<style scoped>
    @media only screen and (min-width: 767px) {
        .filterType {
           width: 350px;
        }
    }

    @media only screen and (max-width: 767px) {
        .filterType {
            flex-grow: 1;
        }
    }
</style>
