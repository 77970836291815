<template>
    <el-select
        v-model="action"
        class="filter"
        @change="search"
    >
        <el-option
            v-for="item in actionsList"
            :key="item.param"
            :value="item.param"
            :label="labelItem(item)"
        />
    </el-select>
</template>


<script>
    import _assign from 'lodash/assign'
    import actionsList from '~/components/searchPage/actionActivitiesLog'

    export default {

        data() {
            return {
                action: this.$route.query.action || '',
                actionsList,
            }
        },

        methods: {
            search() {
                this.$router.push({
                    query: _assign({}, this.$route.query, {
                        action: this.action,
                        page: 1,
                    }),
                })
            },

            labelItem(item) {
                return this.$t(item.text)
            },
        },
    }
</script>
