// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="container pt-3 pb-1">
        <v-row class="mb-3">
            <v-col xs="12" lg="6" class="d-flex align-items-center mb-1">
                <div class="section-title-line full-width">
                    <h4 class="text-uppercase">
                        {{ $t('getStarted.title') }} ({{ pagination.total }})
                    </h4>
                    <hr class="mx-1 filler-line">
                </div>
            </v-col>
            <v-col
                xs="12"
                sm="6"
                md="6"
                lg="3"
                class="mb-1"
            >
                <span class="mr-1">{{ $t('getStarted.type.title') }}</span>
                <el-select
                    :value="type"
                    class="el-select--small"
                    @change="(value) => applyChanges('type', value)"
                >
                    <el-option
                        v-for="(option, key, index) in typeOptions"
                        :key="index"
                        :label="$t(option.text)"
                        :value="option.value"
                    >
                        {{ $t(option.text) }}
                    </el-option>
                </el-select>
            </v-col>
            <v-col
                xs="12"
                sm="6"
                md="6"
                lg="3"
                class="mb-1"
            >
                <span class="mr-1">{{ $t('getStarted.sort.title') }}</span>
                <el-select
                    :value="sort"
                    class="el-select--small"
                    @change="(value) => applyChanges('sort', value)"
                >
                    <el-option
                        v-for="(option, key, index) in sortOptions"
                        :key="index"
                        :label="$t(option.text)"
                        :value="option.value"
                    >
                        {{ $t(option.text) }}
                    </el-option>
                </el-select>
            </v-col>
        </v-row>

        <empty v-if="tags.length <= 0">
            <p> {{ $t('empty') }} </p>
        </empty>

        <div v-else>
            <div class="row block-exhibition">
                <div v-for="(tag, index) in tags" :key="index" class="col-sm-6 col-lg-4 my-2 exhibition-item">
                    <tag-item :tag="tag" />
                </div>
            </div>
            <pagination
                :current-page="pagination.current_page"
                :last-page="pagination.total_pages"
                :path="path"
                :query="paginationQuery"
            />
        </div>
    </div>
</template>

<script>
    import _assign from 'lodash/assign'
    import { mapGetters } from 'vuex'
    import { getTags } from 'viblo-sdk/api/tags'
    import { defaultTitle } from '~/plugins/seo'
    import { handlePageError } from '~/utils/pages'
    import { normalizeTagList } from '~/components/entities/normalize'

    import Empty from '~/components/shared/Empty.vue'
    import TagItem from '~/components/tags/Card.vue'
    import Pagination from '~/components/Pagination.vue'

    const filterFromQuery = query => ({
        page: (typeof query.page === 'undefined') ? 1 : query.page,
        sort: (typeof query.sort === 'undefined') ? 'followers' : query.sort,
        scope: (typeof query.scope === 'undefined' ? 'normal' : 'qa'),
        type: (typeof query.type === 'undefined' ? 'none' : query.type),
    })

    const sortOptions = [
        {
            text: 'getStarted.sort.followers',
            value: 'followers',
        },
        {
            text: 'getStarted.sort.posts',
            value: 'posts',
        },
        {
            text: 'getStarted.sort.question',
            value: 'questions',
        },
    ]

    const typeOptions = [
        {
            text: 'getStarted.type.all',
            value: 'none',
        },
        {
            text: 'getStarted.type.technical',
            value: 'technical',
        },
        {
            text: 'getStarted.type.others',
            others: 'questions',
        },
    ]

    export default {
        components: {
            Empty,
            TagItem,
            Pagination,
        },

        data() {
            return {
                sortOptions,
                typeOptions,
                pagination: {
                    current_page: 1,
                    total_pages: 0,
                },
            }
        },

        computed: {
            ...mapGetters('notifications', ['withUnreadCount']),
            ...mapGetters('auth', ['authenticated']),

            tags() {
                return this.$store.getters['entities/tags/mapId'](this.tagIds)
            },

            path() {
                return this.$route.path
            },

            page() {
                return (typeof this.$route.query.page === 'undefined') ? 1 : this.$route.query.page
            },

            sort() {
                return (typeof this.$route.query.sort === 'undefined') ? 'followers' : this.$route.query.sort
            },

            type() {
                return (typeof this.$route.query.type === 'undefined') ? 'none' : this.$route.query.type
            },

            paginationQuery() {
                return {
                    sort: this.sort,
                    type: this.type,
                }
            },
        },

        async asyncData({
            store, error, query,
        }) {
            try {
                const filters = filterFromQuery(query)
                const { tags, pagination } = await getTags(filters)
                    .then(response => ({
                        tags: response.data,
                        pagination: response.meta.pagination,
                    }))

                const normalizedTags = normalizeTagList(tags)
                const tagIds = normalizedTags.result

                store.commit('entities/tags/put', normalizedTags.entities.tags)

                return { tagIds, pagination }
            } catch (e) {
                return handlePageError(error, e)
            }
        },

        watchQuery: ['page', 'sort', 'type'],

        methods: {
            applyChanges(key, value) {
                const page = key === 'type' || key === 'sort' ? 1 : (this.$route.query.page || 1)
                const query = _assign({}, this.$route.query, {
                    [key]: value,
                    page,
                })
                this.$router.push({ path: '/tags', query })
            },
        },

        head() {
            return {
                title: this.withUnreadCount(`Tags - ${defaultTitle}`),
            }
        },

        analytics: {
            title: 'All tags',
            analyticsFields: {
                dimension1: 'tags.view',
            },
        },
    }

</script>

<style scoped>
    .el-select--small {
        max-width: 11.625rem;
    }
</style>
