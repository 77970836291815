// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <empty v-if="userIds.length <= 0">
        <slot name="empty" />
    </empty>

    <div v-else>
        <div class="block-exhibition row" :class="{'row-followers-tags' : isFollower}">
            <div
                v-for="(id, index) in userIds"
                :key="index"
                :class="isFollower ? 'followers-tags' : 'col-sm-6 col-xl-4'"
            >
                <user-grid-item :user-id="id" :is-follower="isFollower" />
            </div>
        </div>

        <pagination
            v-if="pagination"
            :current-page="pagination.current_page"
            :last-page="pagination.total_pages"
        />
    </div>
</template>

<script>
    import _get from 'lodash/get'
    import { mapState } from 'vuex'
    import Empty from '~/components/shared/Empty.vue'
    import Pagination from '~/components/subdomains/Pagination.vue'
    import UserGridItem from '~/components/users/UserItem.vue'

    export default {
        components: {
            Empty,
            UserGridItem,
            Pagination,
        },
        props: {
            storePath: {
                type: String,
                required: true,
            },
            actions: {
                type: Object,
                default: () => ({}),
            },

            isFollower: {
                type: Boolean,
                default: false,
            },
        },
        provide() {
            return {
                follow: this.actions.follow,
            }
        },
        computed: mapState({
            userIds(state) {
                return _get(state, [...this.storePath.split('/'), 'current'])
            },
            pagination(state) {
                return _get(state, [...this.storePath.split('/'), 'pagination'])
            },
        }),
    }
</script>

<style lang="scss" scoped>
.row-followers-tags {
    margin: 0 !important;
}

@media screen and (min-width: 1200px) {
    .followers-tags {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
}

@media screen and (max-width: 1200px) {
    .followers-tags {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media screen and (max-width: 500px) {
    .followers-tags {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
</style>

<style lang="scss">
.theme-dark {
    ul.pagination {
        & > li {
            a {
                border: none;

                &.active {
                    color: #fff;
                    border: solid 2px #fff;
                }
            }
        }
    }
}
</style>
