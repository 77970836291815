export default [
    {
        param: '',
        text: 'activities.filterType.all',
    },
    {
        param: 'post',
        text: 'activities.filterType.post',
    },
    {
        param: 'question',
        text: 'activities.filterType.question',
    },
    {
        param: 'series',
        text: 'activities.filterType.series',
    },
    {
        param: 'organization',
        text: 'activities.filterType.organization',
    },
    {
        param: 'answer',
        text: 'activities.filterType.answer',
    },
    {
        param: 'tag',
        text: 'activities.filterType.tag',
    },
    {
        param: 'comment',
        text: 'activities.filterType.comment',
    },
    {
        param: 'user',
        text: 'activities.filterType.user',
    },
]
