// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div v-if="tags.length > 0" class="popular-tags">
        <div class="section-title-line">
            <h4 class="text-uppercase">
                {{ title }}
            </h4>
            <hr class="filler-line">
        </div>
        <div class="sidebar-box-content">
            <div class="tags">
                <nuxt-link
                    v-for="tag in tags"
                    :key="tag.slug"
                    :to="to(tag)"
                    class="el-tag el-tag--small link bg-light d-inline-flex p-0 mr-05 mb-05"
                >
                    <span class="px-05 text-dark">{{ tag.name }}</span>
                    <span class="px-05 bg-primary text-white">{{ tag[counterField] }}</span>
                </nuxt-link>
            </div>
        </div>
        <div class="sidebar-box-links">
            <nuxt-link class="accented-link" to="/tags">
                <i class="fa fa-tags mr-05" aria-hidden="true" /> {{ $t('detailTag.allTag') }}
            </nuxt-link>
        </div>
    </div>
</template>

<script>
    import { routeToTag } from '~/lib/functions'

    export default {
        props: {
            tags: Array,
            qaMode: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            counterField() {
                return this.qaMode ? 'questions_count' : 'posts_count'
            },
            title() {
                return this.qaMode ? this.$t('detailTag.topTag') : this.$t('detailTag.popularTag')
            },
        },

        methods: {
            to(tag) {
                return this.qaMode ? routeToTag(tag, 'questions') : routeToTag(tag)
            },
        },
    }
</script>

<style lang="scss">
    .popular-tags {
        .el-tag {
            overflow: hidden;
            border-radius: 5px;
        }
    }
</style>
