import _get from 'lodash/get'
import _last from 'lodash/last'
import { mapGetters } from 'vuex'
import { defaultTitle as shortSiteName } from '~/plugins/seo'

export default ({ defaultTitle = '' }) => ({
    computed: {
        ...mapGetters('notifications', ['withUnreadCount']),

        pageTitle() {
            return _get(_last(this.$route.matched), 'components.default.options.meta.title', defaultTitle)
        },
    },

    head() {
        return {
            title: this.withUnreadCount(this.$t(`${this.pageTitle}`).concat(` - ${shortSiteName}`)),
        }
    },
})
