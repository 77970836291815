// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="d-flex align-items-center justify-content-between">
        <span v-if="showButton" class="mr-05">Date range: </span>

        <el-date-picker
            :key="componentKey"
            v-model="dateRange"
            :picker-options="pickerOptions"
            type="daterange"
            format="MM/dd/yyyy"
            range-separator="-"
            :start-placeholder="$t('myContent.search.startDate')"
            :end-placeholder="$t('myContent.search.endDate')"
            align="left"
            size="small"
            :class="{date: !showButton}"
            unlink-panels
            @change="go"
        />

        <ElButton
            v-if="showButton"
            size="small"
            class="ml-05"
            @click.prevent="go"
        >
            <slot name="apply-text">
                {{ $t('myContent.search.apply') }}
            </slot>
        </ElButton>
    </div>
</template>

<script>
    import _assign from 'lodash/assign'
    import format from 'date-fns/format'

    const dateString = date => (date ? format(new Date(date), 'yyyy-MM-dd') : '')

    export default {
        props: {
            from: String,
            to: String,
            filters: Array,
            showButton: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                dateRange: [this.from, this.to],
                componentKey: 0,
            }
        },

        computed: {
            pickerOptions() {
                const shortcuts = this.filters
                    ? this.filters.map(item => ({
                        text: item.text,
                        onClick: (picker) => {
                            picker.$emit('pick', item.dates)
                        },
                    })) : null

                return {
                    firstDayOfWeek: 1,
                    shortcuts,
                }
            },

            locale() {
                return this.$store.state.i18n.locale
            },
        },


        watch: {
            from(value) {
                if (this.value) {
                    this.value[0] = value
                } else {
                    this.value = [value, null]
                }
            },

            to(value) {
                if (this.value) {
                    this.value[1] = value
                } else {
                    this.value = [null, value]
                }
            },

            locale() {
                this.componentKey += 1
            },
        },

        methods: {
            go() {
                this.filter(...(this.dateRange || [null]))
            },

            filter(from = '', to = '') {
                this.$router.push({
                    query: _assign({}, this.$route.query, {
                        from: dateString(from),
                        to: dateString(to),
                    }),
                })
            },
        },
    }
</script>

<style lang="scss">
    .date {
        height: 40px !important;
    }
</style>
