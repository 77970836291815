export default [
    {
        param: '',
        text: 'activities.filterAction.all',
    },
    {
        param: 'subscribe',
        text: 'activities.filterAction.clip',
    },
    {
        param: 'unsubscribe',
        text: 'activities.filterAction.unclip',
    },
    {
        param: 'upvote',
        text: 'activities.filterAction.upVote',
    },
    {
        param: 'downvote',
        text: 'activities.filterAction.downVote',
    },
    {
        param: 'unvote',
        text: 'activities.filterAction.unVote',
    },
    {
        param: 'accept',
        text: 'activities.filterAction.accept',
    },
    {
        param: 'deleted',
        text: 'activities.filterAction.delete',
    },
    {
        param: 'upvote/changed',
        text: 'activities.filterAction.upVoteUndo',
    },
    {
        param: 'downvote/changed',
        text: 'activities.filterAction.downVoteUndo',
    },
]
