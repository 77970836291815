// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="container mb-1">
        <header class="py-md-1">
            <about :tag="tag" />
        </header>

        <div class="row flex-column-reverse flex-lg-row">
            <div class="col-lg-8">
                <div class="tag-tabs mb-1">
                    <heading-tabs :active-tab-index="activeTabIndex">
                        <heading-tabs-item
                            :to="tab()"
                            index="posts"
                        >
                            {{ $t('detailTag.posts') }}
                        </heading-tabs-item>
                        <heading-tabs-item
                            :to="tab('series')"
                            index="series"
                        >
                            {{ $t('detailTag.series') }}
                        </heading-tabs-item>
                        <heading-tabs-item
                            :to="tab('questions')"
                            index="questions"
                        >
                            {{ $t('detailTag.questions') }}
                        </heading-tabs-item>
                        <heading-tabs-item
                            :to="tab('followers')"
                            index="followers"
                        >
                            {{ $t('detailTag.followers') }}
                        </heading-tabs-item>
                    </heading-tabs>
                </div>

                <nuxt-child />
            </div>

            <div class="col-lg-4">
                <tag-profile :tag="tag" />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'
    import { getTagInfo } from 'viblo-sdk/api/tags'
    import { routeToTag } from '~/lib/functions'
    import { defaultTitle } from '~/plugins/seo'
    import { handlePageError } from '~/utils/pages'
    import { normalizeTag } from '~/components/entities/normalize'

    import About from '~/components/tags/About.vue'
    import TagProfile from '~/components/tags/Profile.vue'
    import HeadingTabs from '~/components/shared/HeadingTabs.vue'
    import HeadingTabsItem from '~/components/shared/HeadingTabsItem.vue'

    export default {
        scrollToTop: true,

        components: {
            About,
            TagProfile,
            HeadingTabs,
            HeadingTabsItem,
        },

        computed: {
            ...mapGetters('notifications', ['withUnreadCount']),
            ...mapState('auth', ['user']),

            ...mapState({
                tag(state, getters) {
                    return getters['entities/tags/get'](this.tagId)
                },
            }),

            activeTabIndex() {
                const arrayPath = this.$route.path.split('/')

                return arrayPath[3] || 'posts'
            },
        },

        async asyncData({ store, error, params }) {
            try {
                const { data: tag } = await getTagInfo(params.tag.toLowerCase())

                const normalized = normalizeTag(tag)

                store.commit('entities/tags/put', normalized.entities.tags)

                return { tagId: normalized.result }
            } catch (e) {
                return handlePageError(error, e)
            }
        },

        methods: {
            tab(page) {
                return routeToTag(this.tag, page)
            },
        },

        head() {
            return {
                title: this.withUnreadCount(`${this.tag.name} - ${defaultTitle}`),
            }
        },

        analytics: route => ({
            title: route.params.tag,
            analyticsFields: {
                dimension1: 'tags.view',
            },
        }),
    }
</script>

<style>
    .tag-tabs {
        border-bottom: 1px solid #d6d6d7;
    }
</style>
