export const getSortTypes = context => [
    {
        label: context.$t('tags.sort.newestPosts'),
        value: 'newest_posts',
    },
    {
        label: context.$t('tags.sort.mostCliped'),
        value: 'most_clipped',
    },
    {
        label: context.$t('tags.sort.mostViewed'),
        value: 'most_viewed',
    },
    {
        label: context.$t('tags.sort.mostVoted'),
        value: 'most_voted',
    },
]
