// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <question-feed feed-store="tagView/questions" @request-reload="reload" />
</template>

<script>
    import { handlePageError } from '~/utils/pages'
    import QuestionFeed from '~/components/feeds/QuestionFeed.vue'

    const fetchStore = (store, tag, params) => store.dispatch('tagView/questions/fetch', { tag, params })

    export default {
        scrollToTop: true,

        components: {
            QuestionFeed,
        },

        fetch({
            store, query, params, error,
        }) {
            return fetchStore(store, encodeURIComponent(params.tag), query)
                .catch(handlePageError(error))
        },

        watchQuery: ['page'],

        methods: {
            reload() {
                fetchStore(this.$store, encodeURIComponent(this.$route.params.tag), this.$route.query)
            },
        },
    }
</script>
