<template>
    <div v-if="puzzle" class="page-writeups">
        <div class="page-header">
            <h1 class="page-header__title">
                Writeups: {{ puzzle.title }}
            </h1>
            <p>
                {{ $t('writeUps.collectionWriteupsDesc') }}
                <a :href="puzzleUrl" target="_blank" rel="noopener">
                    Viblo CTF's puzzle.
                </a>
            </p>
            <el-alert v-if="!puzzle.has_solved" type="success" :closable="false">
                {{ $t('writeUps.congratulationsWriteUpsDesc') }}
                <nuxt-link :to="`/publish/post?category=write-up&puzzle=${puzzle.hash_id}`" class="link">
                    <strong>{{ $t('writeUps.submitAWriteUps') }}</strong>
                </nuxt-link>
                {{ $t('writeUps.to') }} Viblo!
            </el-alert>
        </div>

        <PostFeed store="writeups/puzzlePosts" />
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'
    import { toPuzzle } from '~/utils/url'
    import { getContentRouteParams } from '~/utils/pages'

    import PostFeed from '~/containers/posts/PostFeed.vue'

    export default {
        components: {
            PostFeed,
        },

        computed: {
            ...mapState('writeups/puzzlePosts', ['puzzle']),
            ...mapGetters('notifications', ['withUnreadCount']),

            puzzleUrl() {
                return toPuzzle(this.puzzle.hash_id)
            },

        },

        mounted() {
            this.$store.dispatch('writeups/puzzlePosts/fetch', {
                ...getContentRouteParams(this.$route.params.puzzle, 'puzzleHashId'),
                params: {
                    limit: this.$store.state.settings.perPage,
                    page: this.$route.query.page,
                },
            })
        },

        head() {
            const { title } = this.puzzle ? this.puzzle : { title: 'Puzzle' }
            return { title: this.withUnreadCount(`${title} | Viblo CTF Writeups`) }
        },

        scrollToTop: true,
    }
</script>

<style lang="scss" scoped>
    .page-writeups {
        .page-header {
            margin-bottom: 2rem;
            p {
                font-size: 1rem;
            }
        }
    }
</style>
