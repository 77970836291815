// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="container pt-3 pb-1">
        <div v-if="!unsubscribed">
            <el-card v-if="is_success" class="box-card">
                <div class="text-center">
                    <p class="unsubscribe-box-header">
                        Unsubscribe Successful !
                    </p>
                    <p> You have been removed from Viblo Newsletter.</p>

                    <el-button
                        class="mt-1"
                        size="medium"
                        type="primary"
                        plain
                        @click="goHome"
                    >
                        <i class="el-icon-back" /> Go home
                    </el-button>
                </div>
            </el-card>
            <el-alert
                v-else
                :closable="false"
                type="info"
                title=""
                class="mb-1"
                center
            >
                <i class="el-icon-loading" aria-hidden="true" /> Unsubscribing. Please wait ....
            </el-alert>
        </div>
        <div v-else>
            <already-unsubscribed />
        </div>
    </div>
</template>

<script>
    import ElCard from 'element-ui/lib/card'
    import { unsubscribe } from '~/api/unsubscribe'
    import AlreadyUnsubscribed from './already-unsubscribed.vue'

    export default {
        components: {
            AlreadyUnsubscribed,
            ElCard,
        },

        data() {
            return {
                is_success: false,
                unsubscribed: false,
            }
        },

        async created() {
            if (process.client) {
                const { data } = await unsubscribe(this.$route.params.username, this.$route.query.signature)

                if (!data) {
                    this.unsubscribed = true
                    return
                }
                this.is_success = true
            }
        },

        methods: {
            goHome() {
                this.$router.push('/')
            },
        },
    }
</script>

<style scoped>
    .unsubscribe-box-header {
        font-size: 18px;
        font-weight: bold;
        color: #67C23A;
    }
</style>
