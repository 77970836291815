<template>
    <div :class="$style['search-box']">
        <el-input
            v-model="value"
            :placeholder="placeholder"
            :clearable="true"
            @keyup.native.enter="search"
            @clear="search"
        >
            <slot slot="prepend" name="prepend">
                <el-select v-if="searchs" v-model="searchBy">
                    <el-option
                        v-for="(searchOptions, index) in searchs"
                        :key="index"
                        :value="searchOptions.param"
                        :label="searchOptions.text"
                    />
                </el-select>
            </slot>

            <el-button
                slot="append"
                icon="el-icon-search"
                @click="search"
            />
        </el-input>
    </div>
</template>

<script>
    import _find from 'lodash/find'
    import _assign from 'lodash/assign'

    const getCurrentSearch = (query, searchs) => _find(searchs, _ => query[_.param] != null) || searchs[0]

    export default {
        props: {
            searchs: Array,
            placeholder: {
                type: String,
                default: 'Search',
            },
            queryParam: {
                type: String,
                default: 'q',
            },
        },

        data() {
            const searchBy = this.searchs ? getCurrentSearch(this.$route.query, this.searchs).param : null
            const searchByParam = this.searchs ? searchBy : this.queryParam

            return {
                searchBy,
                value: this.$route.query[searchByParam],
            }
        },

        computed: {
            searchParam() {
                return this.searchs ? this.searchBy : this.queryParam
            },
        },

        methods: {
            search() {
                this.$router.push({
                    query: _assign({}, this.$route.query, {
                        [this.searchParam]: this.value,
                        page: 1,
                    }),
                })
            },
        },
    }
</script>

<style lang="scss" module>
    .search-box {
        :global .el-select .el-input {
            min-width: 125px;
        }

        :global .el-input-group {
            &__prepend {
                background-color: white;
            }
        }
    }
</style>
