// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <empty v-if="series.length <= 0">
        <p> {{ $t('empty') }} </p>
    </empty>

    <div v-else>
        <series-list :data="series" />
        <pagination v-if="pagination" :current-page="pagination.current_page" :last-page="pagination.total_pages" />
    </div>
</template>

<script>
    import { getTagSeries } from 'viblo-sdk/api/tags'
    import { handlePageError } from '~/utils/pages'
    import Empty from '~/components/shared/Empty.vue'
    import Pagination from '~/components/Pagination.vue'
    import SeriesList from '~/components/series/List.vue'

    export default {
        components: {
            Empty,
            Pagination,
            SeriesList,
        },

        data: () => ({ series: [] }),

        async asyncData({ query, params }) {
            try {
                const { data, meta } = await getTagSeries(encodeURIComponent(params.tag), query)

                return {
                    pagination: meta.pagination,
                    series: data,
                }
            } catch (e) {
                handlePageError(e)
            }
            return {}
        },

        watchQuery: ['page'],
    }
</script>
