// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<script>
    import { routeToTag as to } from '~/lib/functions'
    import formatNumber from '~/filters/formatNumber'

    export default {
        functional: true,

        render: (h, { props: { tag, stats } }) => (
            <div class='tag-stats'>
                <div class='stats-item'>
                    <nuxt-link class='link' to={to(tag)}>
                        <span class='stats-figure'>{ formatNumber(tag.posts_count) }</span>
                        <span class='stats-label'>{ stats.posts }</span>
                    </nuxt-link>
                </div>
                <div class='stats-item'>
                    <nuxt-link class='link' to={to(tag, 'questions')}>
                        <span class='stats-figure'>{ formatNumber(tag.questions_count) }</span>
                        <span class='stats-label'>{ stats.questions }</span>
                    </nuxt-link>
                </div>
                <div class='stats-item-followers'>
                    <nuxt-link class='link' to={to(tag, 'followers')}>
                        <span class='stats-figure'>{ formatNumber(tag.followers_count) }</span>
                        <span class='stats-label'>{ stats.followers }</span>
                    </nuxt-link>
                </div>
            </div>
        ),
    }
</script>

<style lang="scss">
    @import "../../assets/sass/bootstrap/colors";
    @import "../../assets/sass/bootstrap/mixins";

    .tag-stats {
        display: flex;

        .stats-item {
            flex-grow: 1;
            flex-basis: 30%;
            padding: .5rem .8rem;

            .stats-figure {
                display: block;
                line-height: 1.2;
                font-size: 1.25rem;
            }

            .stats-label {
                color: $gray-light;
            }
        }
        .stats-item-followers {
            flex-grow: 1;
            flex-basis: 40%;
            padding: .5rem .8rem;

            .stats-figure {
                display: block;
                line-height: 1.2;
                font-size: 1.25rem;
            }

            .stats-label {
                color: $gray-light;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .tag-stats {
            border: 1px solid $gray-lighter;

            .stats-item {
                &:not(:first-child):not(:last-child) {
                    border-left: 1px solid $gray-lighter;
                    border-right: 1px solid $gray-lighter;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        .tag-stats {
            margin: 1rem -15px;
            padding: 0 15px;
            border: solid #ddd;
            border-width: 6px 0;
        }
    }
</style>
