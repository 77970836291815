// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div>
        <el-dropdown class="post-layout-switcher mb-1" size="medium" trigger="click">
            <span class="cursor-pointer">
                {{ $t('tags.sort.title') }}: <b>{{ activeSort.label }}</b>
            </span>

            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                    v-for="(type, key) in sortTypes"
                    :key="key"
                    :class="{ 'el-dropdown-menu__item--active': type.value == activeSort.value }"
                    @click.native="sort(type)"
                >
                    {{ type.label }}
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        <post-feed store="tagView/posts" />
    </div>
</template>

<script>
    import _filter from 'lodash/filter'
    import { handlePageError } from '~/utils/pages'
    import { ACTION_FETCH_TAG_POST_FEED } from '~/store/tagView/posts'
    import PostFeed from '~/containers/posts/PostFeed.vue'
    import { getSortTypes } from '~/constants/sort-posts'

    export default {
        scrollToTop: true,

        components: {
            PostFeed,
        },

        computed: {
            sortTypes() {
                return getSortTypes(this)
            },

            activeSort() {
                const active = this.$route.query.sort || 'newest_posts'

                return _filter(this.sortTypes, type => type.value === active)[0]
            },
        },

        fetch({
            store, query, params, error,
        }) {
            return store.dispatch(ACTION_FETCH_TAG_POST_FEED, {
                tag: encodeURIComponent(params.tag),
                params: query,
                query: {
                    sort: this.activeSort,
                },
            }).catch(handlePageError(error))
        },

        watchQuery: ['page', 'sort'],

        methods: {
            sort(sortType) {
                sortType = sortType || this.activeSort
                this.$router.push(`${this.$route.params.tag}?sort=${sortType.value}`)
            },
        },
    }
</script>
