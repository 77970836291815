<template>
    <div ref="root" />
</template>

<script>
    import viI18n from '~/lang/emoji-picker-element/vi'

    export default {
        mounted() {
            const { picker } = require('../../lib/emojiPicker')

            if (this.$i18n.locale === 'vi') {
                picker.i18n = viI18n
            }

            this.$refs.root.appendChild(picker)

            picker.addEventListener('emoji-click', (event) => {
                this.$emit('emojiClick', event.detail)
            })
        },
    }
</script>
