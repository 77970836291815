// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="tag-about">
        <div class="d-flex align-items-center my-1">
            <nuxt-link :to="url">
                <img
                    :src="tag.image"
                    :alt="tag.name"
                    class="tag-image mr-1"
                >
            </nuxt-link>

            <div>
                <tag-header
                    :tag="tag"
                    :can-change="isCollaborator"
                    html-tag="h1"
                    class="word-break"
                />

                <subscribe
                    ref="subscribe"
                    :send="handleSubscribe"
                    :subscribable="{ type: 'Tag', 'id': tag.slug }"
                >
                    <button
                        :class="tag.following ? 'btn-primary following' : 'btn-white'"
                        class="btn btn-small-padding btn-follow"
                        @click="$refs.subscribe.handle(!tag.following)"
                    >
                        <span v-if="tag.following"><i class="fa fa-check" /> {{ $t('detailTag.following') }}</span>
                        <span v-else><i class="fa fa-plus" /> {{ $t('detailTag.follow') }}</span>
                    </button>
                </subscribe>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import { routeToTag } from '~/lib/functions'
    import Subscribe from '~/components/widgets/Subscribe.vue'
    import TagHeader from '~/components/tags/TagHeader.vue'

    export default {
        components: {
            Subscribe,
            TagHeader,
        },

        props: {
            tag: {
                type: Object,
                required: true,
            },
        },

        computed: {
            ...mapGetters('auth', ['isCollaborator']),

            url() {
                return routeToTag(this.tag)
            },
        },

        methods: {
            ...mapActions('entities/tags', ['subscribe']),

            handleSubscribe(value) {
                return this.subscribe({ tag: this.tag, value })
            },
        },
    }
</script>

<style lang="scss">
    @import "../../assets/sass/bootstrap/mixins";

    .tag-about {
        img.tag-image {
            width: 80px;
        }
    }

    @include media-breakpoint-down(md) {
        .tag-about {
            .tag-name {
                font-size: 1.3rem;
            }

            .btn-follow {
                font-size: .75rem;
            }
        }
    }
</style>
