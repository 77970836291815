// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <user-grid :actions="{ follow }" store-path="tagView/followers" :is-follower="true">
        <template slot="empty">
            This tag doesn't have any followers, yet.
        </template>
    </user-grid>
</template>

<script>
    import { mapActions } from 'vuex'
    import { handlePageError } from '~/utils/pages'
    import UserGrid from '~/components/users/UserGrid.vue'

    export default {
        name: 'TagFollowers',

        components: {
            UserGrid,
        },

        scrollToTop: true,

        fetch: ({
            store, params, query, error,
        }) => store.dispatch('tagView/followers/fetch', {
            tag: encodeURIComponent(params.tag),
            params: query,
        }).catch(handlePageError(error)),

        watchQuery: ['page'],

        methods: {
            ...mapActions('entities/users', ['follow']),
        },
    }
</script>
