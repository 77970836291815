<template>
    <el-table :data="activities" @filter-change="onFilter">
        <el-table-column
            prop="created_at"
            :label="`${$t('activities.table.date')} (${total})`"
            width="150"
            show-overflow-tooltip
            :formatter="formatter"
        />

        <el-table-column
            prop="type"
            :label="$t('activities.table.activity.title')"
            width="160"
            :formatter="formatClip"
        />

        <el-table-column
            prop="interactable_type"
            :label="$t('activities.table.type')"
            width="100"
        >
            <template slot-scope="scope">
                {{ $t(`activities.table.interactableTypes.${scope.row.interactable_type}`) }}
            </template>
        </el-table-column>

        <el-table-column
            :label="$t('activities.table.title')"
            prop="interactable.title"
            show-overflow-tooltip
        />

        <el-table-column
            fixed="right"
            :label="$t('activities.table.actions')"
            align="center"
            width="100"
        >
            <div slot-scope="scope">
                <a
                    v-if="scope.row.interactable"
                    :href="scope.row.interactable.url"
                    target="_blank"
                >
                    <el-button size="mini">
                        <i class="fa fa-external-link" />
                    </el-button>
                </a>
            </div>
        </el-table-column>
    </el-table>
</template>

<script>
    import _assign from 'lodash/assign'
    import _include from 'lodash/includes'
    import { humanizeTime } from '~/filters/dates'

    export default {
        props: {
            activities: Array,
            total: {
                type: Number,
                default: 0,
            },
        },

        computed: {
            activeType() {
                if (Array.isArray(this.$route.query.type)) {
                    return this.$route.query.type
                } return [this.$route.query.type]
            },

            active() {
                if (Array.isArray(this.$route.query.action)) {
                    return this.$route.query.action
                } return [this.$route.query.action]
            },
        },

        methods: {
            formatter(row) {
                return `${humanizeTime(row.created_at)}`
            },

            onFilter(filter) {
                this.$router.push({
                    query: _assign({}, this.$route.query, {
                        type: filter.type ? filter.type[0] : this.$route.query.type,
                        action: filter.action ? filter.action[0] : this.$route.query.action,
                        page: 1,
                    }),
                })
            },

            formatClip(row) {
                const humanizeType = () => {
                    const followAbleTypes = ['User', 'Tag', 'Organization']
                    const clippAbleTypes = ['Post', 'Question', 'Series']

                    if (row.type === 'subscribe' && _include(clippAbleTypes, row.interactable_type)) return 'clip'
                    if (row.type === 'unsubscribe' && _include(clippAbleTypes, row.interactable_type)) return 'unclip'
                    if (row.type === 'subscribe' && _include(followAbleTypes, row.interactable_type)) return 'follow'
                    if (row.type === 'unsubscribe' && _include(followAbleTypes, row.interactable_type)) return 'unfollow'
                    if (row.type === 'upvote/changed') return 'upvoteUndo'
                    if (row.type === 'downvote/changed') return 'downvoteUndo'
                    if (row.type === 'accept/undo') return 'acceptUndo'
                    if (row.type === 'deleted') return 'delete'
                    if (row.type === 'accept') return 'accept'

                    return row.type
                }

                return (<span>{this.$t(`activities.table.activity.${humanizeType()}`)}</span>)
            },
        },
    }
</script>
